import { Component } from '@vue/runtime-dom'
import { defineStore } from 'pinia'
import { Directions } from '../constants/directions'
import { TranslationKey } from '~/i18n/TranslationKeys'

type sideDrawerState = {
	show: boolean
	openDirection: Directions.LEFT | Directions.RIGHT
	title: TranslationKey
	subtitle: TranslationKey | Date
	childComponent: Component | undefined
	testIds?: {
		container?: string
	}
}

export const useSideDrawerStore = defineStore('🚀 side drawer', {
	state: (): sideDrawerState => ({
		show: false,
		openDirection: Directions.LEFT,
		title: '' as TranslationKey,
		subtitle: '' as TranslationKey,
		childComponent: undefined,
		testIds: undefined,
	}),
	actions: {
		openDrawer(
			mouseX: number,
			title: TranslationKey,
			subtitle: TranslationKey | Date,
			component: Component,
			testIds?: { container?: string }
		) {
			const breakPoint = window.innerWidth / 2
			this.openDirection = breakPoint < mouseX ? Directions.LEFT : Directions.RIGHT
			this.title = title
			this.subtitle = subtitle
			this.childComponent = markRaw(component)
			this.show = true
			this.testIds = testIds
		},
		closeDrawer() {
			this.show = false
		},
	},
})
